import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import NavSection from '../../components/nav-section';
import Footer from '../../components/footer';
import Layout from '../../components/layout';
import VenuesSection from '../../components/venues-section';
import BookingForm from '../../components/booking-form';
import Carousel from 'react-bootstrap/Carousel';
import { find } from 'lodash';
import { includes } from 'lodash';

class SpecialEventsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packagesSection: 'active',
      venuesSection: '',
      bookingSection: '',
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.sectionScrollHandler);
    }
  }

  sectionScrollHandler = e => {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    let packagesSectionPosition =
      document.getElementById('packagesSection') &&
      document.getElementById('packagesSection').offsetTop;
    let venuesSectionPosition =
      document.getElementById('venuesSection') &&
      document.getElementById('venuesSection').offsetTop;
    let bookingSectionPosition =
      document.getElementById('bookingSection') &&
      document.getElementById('bookingSection').offsetTop;

    if (winScroll >= packagesSectionPosition) {
      document
        .querySelectorAll('.side-scrollers ,.center-btns')
        .forEach(function (el) {
          el.classList.remove('active');
        });
      document.getElementById('btn-01') &&
        document.getElementById('btn-01').classList.add('active');
    }
    if (winScroll >= venuesSectionPosition - 10) {
      document
        .querySelectorAll('.side-scrollers ,.center-btns')
        .forEach(function (el) {
          el.classList.remove('active');
        });
      document.getElementById('btn-02') &&
        document.getElementById('btn-02').classList.add('active');
    }
    if (winScroll >= bookingSectionPosition - 10) {
      document
        .querySelectorAll('.side-scrollers ,.center-btns')
        .forEach(function (el) {
          el.classList.remove('active');
        });
      document.getElementById('btn-03') &&
        document.getElementById('btn-03').classList.add('active');
    }
  };

  scrollHandler = e => {
    this.setState({
      packagesSection: '',
      venuesSection: '',
      bookingSection: '',
    });
    this.setState({ [e.target.dataset.id]: 'active' });
    var target = document.getElementById(e.target.dataset.id);
    target.scrollIntoView(true);
  };
  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    
    const footer = get(this, 'props.data.allContentfulFooter.edges')
    
    const files = this.props.data.allContentfulDownloads.nodes;
    const { packagesSection, bookingSection } = this.state;
    const weddingPackages = find(files, { 'title': 'Our Wedding Packages' });

    const {
      pageTitle,
      tabName01,
      tabName02,
      tabName03,
      mobileTabName01,
      mobileTabName02,
      mobileTabName03,
      sliderImages,
      sliderCaption,
      sliderButton1,
      sliderButton2,
      inclusionsTitle,
      inclusionsSubTitle,
      inclusionsTable,
      venuesSection,
      weddingTableBtn,
      weddingTableCaption,
      weddingTableTitle,
    } = get(this, 'props.data.allContentfulEventsFirstSubPage.edges')[0].node;

    const imageUrls = sliderImages.map(image => image.file.url)

    const bookingForm = get(this, 'props.data.allContentfulBookingForm.edges')[0].node;

    return (
      <Layout>
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title="Events - Weddings" />

        <div className="venue-header sm-w85">
          <h1 className="mb-0">{pageTitle}</h1>
        </div>
        <section id="link-tabs" className="pt-4 pb-4">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <ul className="nav justify-content-center">
                  <li className="font-gtaM">
                    <input
                      data-id="packagesSection"
                      id="btn-01"
                      className={`center-btns nav-link quick-link ${packagesSection}`}
                      onClick={this.scrollHandler}
                      value={tabName01}
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <input
                      data-id="venuesSection"
                      id="btn-02"
                      className={`center-btns nav-link quick-link`}
                      onClick={this.scrollHandler}
                      value={tabName02}
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <input
                      data-id="bookingSection"
                      id="btn-03"
                      className={`center-btns nav-link quick-link ${bookingSection}`}
                      onClick={this.scrollHandler}
                      value={tabName03}
                      type="button"
                    />
                  </li>
                  {/* SIDENAV for mobile */}
                  <div className="sidenav d-block d-sm-none">
                    <li className={packagesSection}>
                      <input
                        data-id="packagesSection"
                        className={`font-gtaM ${packagesSection} side-scrollers`}
                        id="li-01"
                        onClick={this.scrollHandler}
                        value={mobileTabName01}
                        type="button"
                      />
                    </li>
                    <li className={venuesSection}>
                      <input
                        data-id="venuesSection"
                        className={`font-gtaM ${venuesSection} side-scrollers`}
                        id="li-02"
                        onClick={this.scrollHandler}
                        value={mobileTabName02}
                        type="button"
                      />
                    </li>
                    <li className={bookingSection}>
                      <input
                        data-id="bookingSection"
                        className={`font-gtaM ${bookingSection} side-scrollers`}
                        id="li-03"
                        onClick={this.scrollHandler}
                        value={mobileTabName03}
                        type="button"
                      />
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="packagesSection" className="mb-5 mt-4 pb-4  sm-mt90">
          <Carousel className="gray-arrows hidden-indicators">
            {imageUrls.map((image, index) => (
              <Carousel.Item>
                <div className="container sm-w85 pt-4">
                  <div className="venue-whole venue-details">
                    <img src={image} alt={index} />
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="container sm-w85 venue-details">
            <div className="row justify-content-between">
              <div className="col-md-12 text-center mb-2 mt-5">
                <p className="font-bigcas mb-4">
                  {sliderCaption}
                </p>
                <div className="row text-center d-flex ctr-btns action-btns mb-2">
                  <a href="#bookingSection">
                    <button className="btn">{sliderButton1}</button>
                  </a>
                  <Link
                    to={`/about/faqs-and-guidelines#trustedSuppliersSection`}
                  >
                    <button className="btn">{sliderButton2}</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="inclusionsSection" className="mt-4 mb-5 pb-4 ">
          <div className="header sm-w85 bordered-top container pt-3 mb-5">
            <h1>{inclusionsTitle}</h1>
            <p className="font-gtaM">{inclusionsSubTitle}</p>
          </div>

          <Carousel className="gray-arrows hidden-indicators">
            {inclusionsTable.map((offer, index) => (
              <Carousel.Item>
              <div className="container sm-w85 pt-4">
                <div className="venue-whole venue-details">
                  <img src={offer.weddingTableImage.file.url} />
                </div>
              </div>
              <div className="container sm-w85 venue-details">
                <div className="row justify-content-between">
                  <div className="col-lg-7 col-md-8">
                    <h3 className="subhead">{offer?.weddingTableNo}</h3>
                    <h3 className="mt-0">{offer.weddingTableTitle} </h3>
                    <p className="font-bigcas mb-0">
                     {offer.weddingTableCaption}
                    </p>
                  </div>
                  <div className="col-md-4 venue-whole mt-5 pt-3">
                    <div className="inclusions">
                      <h4 className="font-gtaM">{offer.supplierSectionTitle && offer.supplierSectionTitle}</h4>
                      <p id='suppliersList-offers'>
                        {offer.suppliersList?.content[0].content[0].value}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </section>

        <section id="venuesSection" className="">
          <VenuesSection venuesSection={venuesSection}/>
        </section>

        <section className="pt-5 pb-5 wedding-table ">
          <div className="container sm-w85 bordered-top pt-5">
            <h1 className="red mb30">{weddingTableTitle}</h1>
            <p className="mb-5">
              {weddingTableCaption}
            </p>
            <div className="text-center mt-5 pt-3 mb-3">
              <a
                href={weddingPackages?.file?.file?.url}
                alt=""
                target="_blank"
                download
              >
                <button className="btn">{weddingTableBtn}</button>
              </a>
            </div>
          </div>
        </section>

        <section id="bookingSection" className="sm-w85">
          <BookingForm bookingForm={bookingForm}/>
        </section>

        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default SpecialEventsPage;

export const pageQuery = graphql`
  query SpecialEventsPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBookingForm {
      edges {
        node {
          header
          firstNameInputPlaceholder
          lastNameInputPlaceholder
          contactNoInputPlaceholder
          emailInputPlaceholder
          bookingTypeInputPlaceholder {
            name
            value
          }
          dateInputPlaceholder
          timeInputPlaceholder
          venueInputPlaceholder {
            name
            value
            selected_value
          }
          messageInputPlaceholder
          newsletterInputPlaceholder
          submitInputPlaceholder
        }
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulEventsFirstSubPage {
      edges {
        node {
          inclusionsTitle
          inclusionsSubTitle
          inclusionsTable {
            weddingTableCaption
            weddingTableImage {
              file {
                url
              }
            }
            weddingTableNo
            weddingTableTitle
            supplierSectionTitle
            suppliersList {
              content {
                content {
                  value
                }
              }
            }
          }
          mobileTabName01
          mobileTabName02
          mobileTabName03
          pageTitle
          sliderButton1
          sliderButton2
          sliderCaption
          tabName01
          tabName02
          tabName03
          weddingTableBtn
          weddingTableCaption
          weddingTableTitle
          venuesSection {
            locationBtn1
            locationBtn2
            locationBtn3
            locationName1
            locationName2
            locationName3
            locationImage1 {
              file {
                url
              }
            }
            locationImage2 {
              file {
                url
              }
            }
            locationImage3 {
              file {
                url
              }
            }
            sectionCaption
            sectionTitle
          }
          sliderImages {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulBookingForm {
      edges {
        node {
          header
          firstNameInputPlaceholder
          lastNameInputPlaceholder
          contactNoInputPlaceholder
          emailInputPlaceholder
          bookingTypeInputPlaceholder {
            name
            value
          }
          dateInputPlaceholder
          timeInputPlaceholder
          venueInputPlaceholder {
            name
            value
            selected_value
          }
          messageInputPlaceholder
          newsletterInputPlaceholder
          submitInputPlaceholder
        }
      }
    }
    allContentfulDownloads {
      nodes {
        file {
          file {
            url
          }
        }
        title
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
